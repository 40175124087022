import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AutoDialog = makeShortcode("AutoDialog");
const Grid = makeShortcode("Grid");
const Paper = makeShortcode("Paper");
const TextCustom = makeShortcode("TextCustom");
const Img = makeShortcode("Img");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AutoDialog mdxType="AutoDialog">
  <p>Don't give up if your teenager doesn't respond at first. Keep trying!</p>
    </AutoDialog>
    <h1 {...{
      "id": "3-talking-through-the-tough-stuff"
    }}>{`3. Talking through the tough stuff`}</h1>
    <Grid container spacing={5} alignItems="center" mdxType="Grid">
  <Grid item xs={12} md={6} mdxType="Grid">
    <p>
      It's common for teenagers to experience strong emotions. They might look overly worried or so angry they look like
      they are about to explode. In order to manage their emotions effectively, teenagers need to first learn how to
      recognise and understand them. Here are some tips on how you can help your teenager to do this.
    </p>
    <Paper mdxType="Paper">
      <TextCustom paragraph customColor="blue" mdxType="TextCustom">
        <b>Identify</b> - Label the emotion.
      </TextCustom>
      <TextCustom paragraph customColor="maroon" mdxType="TextCustom">
        <b>Validate</b> - Acknowledge your teenager's feelings about the situation.
      </TextCustom>
      <TextCustom paragraph customColor="deepPurple" mdxType="TextCustom">
        <b>Understand</b> - Explore what has led to the emotions.
      </TextCustom>
    </Paper>
  </Grid>
  <Grid item xs={12} md={6} mdxType="Grid">
    <Img src="/images/m1/6-AUS-version.svg" alt="Listening Example" mdxType="Img" />
  </Grid>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      